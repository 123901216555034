<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Client')} ${$t('To Edit')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <!-- Field: Client Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="clientData.name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Phone -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required|integer"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="clientData.phone"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Address -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="address"
                rules="required"
              >
                <b-form-group
                  :label="$t('Address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="clientData.address"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex flex-row-reverse mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              pill
              type="submit"
            >
              {{ $t('Save') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              class="mr-1"
              variant="light"
              pill
              @click="cancel"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import { required, integer, regex } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import clientStoreModule from '../clientStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
      integer,
      regex,
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'pos-client-list' })
    },
  },
  setup(props, context) {
    const STORE_MODULE_NAME = 'client'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, clientStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const blankClientData = {
      name: '',
      phone: '',
      address: '',
      credit_balance: 0,

    }
    const router = context.root.$router
    const clientData = ref(JSON.parse(JSON.stringify(blankClientData)))
    const clientId = router.currentRoute.params.id
    store.dispatch('client/fetchOne', { id: clientId })
      .then(response => {
        const responseData = {
          id: response.data.data.id,
          name: response.data.data.name,
          phone: response.data.data.phone,
          address: response.data.data.address,
          credit_balance: response.data.data.credit_balance,
        }
        clientData.value = responseData
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(clientData.value))
      store.dispatch('client/edit', data)
        .then(response => {
          if (response.status === 200) {
            router.push({ name: 'pos-client-list' })
          } else {
            console.log(response)
          }
        })
        .catch(error => {
          window.swal('Error', JSON.stringify(error.response.data).replace(/"([^"]+)":/g, '$1:'))
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      clientData,
      onSubmit,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
